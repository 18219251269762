import React, { Component } from 'react';
import MaskedInput from 'react-text-mask'
import logo from './logo.png';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      newUser: true,
    };
  }

  componentDidMount(){
    document.title = "Premium Bonus Club"
  }

  handleSubmit = (e) => {
    const formData = new FormData(e.target)
    const user = {}
    e.preventDefault()
    for (let entry of formData.entries()) {
      user[entry[0]] = entry[1]
    }
    if (/\+7\s\(\d{3}\)\s\d{3}-\d{4}/gm.test(user.phone)) {
      user.phone = user.phone.match(/[+\d]/g).join('')
    } else {
      return alert('Поле Телефон не заполнено')
    }
    // if (!/^\d{2}.\d{2}.\d{4}$/gm.test(user.birthday)) {
    //   return alert('Поле Дата рождения не заполнено')
    // }
    this.doRegform(user)
  }

  doRegform = (data) => {
    fetch('http://api.premiumbonus.club/api/regform', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(json => {
        console.log('json', json)
        alert(json.meta.message || 'Успешно')
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message || 'Сервер временно недоступен')
      })
  }

  handleChange = (e) => {
    this.setState({ newUser: e.target.checked })
  }

  render() {
    return (
      <div className="forms wrapper">
        <img src={logo} alt="logo" className="image" />
          <form onSubmit={this.handleSubmit}>
            <fieldset>
              <legend>Заполните форму для регистрации карты</legend>
              <label>
                <input type="checkbox" name="newUser" checked={this.state.newUser} onChange={ this.handleChange } />
                &nbsp;
                <b className="inputLabel">Новый пользователь</b>
              </label>
              {this.state.newUser
                ? (
                  <label>
                    <b>Имя</b>
                    <input type="text" name="firstname" />
                  </label>
                )
                : null
              }
              {this.state.newUser
                ? (
                  <label>
                    <b>Фамилия</b>
                    <input type="text" name="lastname" />
                  </label>
                )
                : null
              }
              {this.state.newUser
                ? (
                  <label>
                    <b>Адрес</b>
                    <input type="text" name="address"/>
                  </label>
                )
                : null
              }
              {this.state.newUser
                ? (
                  <label>
                    <b>Email</b>
                    <input type="email" name="email"/>
                  </label>
                )
                : null
              }
              {this.state.newUser
                ? (
                  <label>
                    <b>Дата рождения</b>
                    <MaskedInput
                      type="text"
                      name="birthday"
                      mask={[/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/,]}
                      guide
                      placeholder="дд.мм.гггг"
                    />
                  </label>
                )
                : null
              }
              <label>
                <b>Телефон</b><b className="red">*</b>
                <MaskedInput
                  type="tel"
                  name="phone"
                  mask={['+', 7, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  guide
                  placeholder="+7 (___) ___-____"
                  required
                />
              </label>
              {this.state.newUser
                ? (
                  <label>
                    <b>Номер новой карты</b><b className="red">*</b>
                    <input type="text" name="newcard" required />
                  </label>
                )
                : null
              }
              <label>
                <b>Номер старой карты (если есть)</b>
                <input type="text" name="oldcard" />
              </label>
              <div className="submitContainer">
                <input type="submit" name="submit" value="Отправить" className="button" />
              </div>
            </fieldset>
          </form>
      </div>
    )
  }
}

export default App;
